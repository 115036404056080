import { useEffect, useState, ReactNode } from 'react';
import {
  Modal,
  ModalClose,
  ModalPrimaryActionButton,
  ModalSecondaryActionButton,
  ModalTertiaryActionButton,
  Typography,
} from '@la/ds-ui-components';
import { FormField } from '@la/types';
import ErrorCard from 'components/ErrorCard/ErrorCard';
import { getLAHostnameParts } from 'lib/utils/urlUtils';
import {
  useDeleteTeamMutation,
  useUpdateTeamMutation,
} from 'redux/services/teamApi';
import { Team } from 'redux/services/types/team';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import {
  CreateTeamForm,
  CreateTeamFormFields,
  TeamLocation,
} from '../../CreateTeamForm/CreateTeamForm';
import * as S from './UpdateTeamDialog.styles';

export const UPDATE_TEAM_ERROR_MESSAGE =
  'There was an error updating your team. Please try updating again in a few seconds.';
export const DELETE_TEAM_ERROR_MESSAGE =
  'There was an error deleting your team. Please try deleting again in a few seconds.';

export type UpdateTeamDialogProps = {
  /**
   * Id of the division
   */
  divisionId: string;
  /**
   * The max age group for the division.
   */
  maxDivisionAgeGroup: number;
  /**
   * Whether or not this team is currently selected for. Includes
   * both divisions that have been saved and divisions that are opened for
   * editing.
   */
  hasDivisionsWithTeamSelected?: boolean;
  /**
   * Called when a team is deleted.
   * @param deletedTeamId Id of the team being deleted.
   */
  onDeleteTeam: (deletedTeamId: string) => void;
  /**
   * Called when a team is updated.
   * @param teamId Id of the team being updated.
   * @param teamAgeGroup Age group of team being updated.
   */
  onUpdateTeam: (teamId: string, teamAgeGroup: number) => void;
  /**
   * The team to be updated or deleted
   */
  team: Team;
  /**
   * Id of the tournament the division belongs to
   */
  tournamentId: string;
  /**
   * Setter called whenever a team cannot be deleted because is already tied to a registration.
   */
  setHasDeleteTeamDivisionError: (error: boolean) => void;
  trigger?: ReactNode;
  customFields: FormField[];
};

export function UpdateTeamDialog({
  divisionId,
  maxDivisionAgeGroup,
  hasDivisionsWithTeamSelected,
  setHasDeleteTeamDivisionError,
  onDeleteTeam,
  onUpdateTeam,
  team,
  tournamentId,
  trigger,
  customFields,
}: UpdateTeamDialogProps) {
  const { subdomain } = getLAHostnameParts();
  const [
    updateTeam,
    { isLoading: isUpdateTeamLoading, error: updateTeamError, reset },
  ] = useUpdateTeamMutation();

  const [isOpen, setIsOpen] = useState<boolean>();

  const { admin1, country, name, status, teamRepresentative } = team;
  const teamFormId = 'update-team-form';

  let defaultLocationValues: TeamLocation;
  if (country === 'USA' && admin1) {
    defaultLocationValues = { country, state: admin1 };
  } else if (country === 'CAN' && admin1) {
    defaultLocationValues = { country, province: admin1 };
  } else {
    defaultLocationValues = { country };
  }

  const defaultValues: CreateTeamFormFields = {
    ...defaultLocationValues,
    teamName: name,
    organization: team.organization,
    ageGroup: team.ageGroup,
    city: team.city,
    repName: teamRepresentative?.name,
    repPhoneNumber: teamRepresentative?.phoneNumber,
    repEmail: teamRepresentative?.email,
    customTeamFields: customFields,
  };

  const [updatedTeam, setUpdatedTeam] =
    useState<CreateTeamFormFields>(defaultValues);

  /**
   * Resets the query state so that errors do not show when
   * reopening the modal.
   */
  useEffect(() => {
    if (!isOpen) {
      reset();
    }
  }, [isOpen, reset]);

  const formHasChanges = !Object.entries(updatedTeam).every(
    ([field, value]) => {
      return defaultValues[field as keyof CreateTeamFormFields] === value;
    }
  );

  const handleUpdateTeam = (formTeam: CreateTeamFormFields) => {
    const {
      teamName,
      state,
      province,
      repName,
      repPhoneNumber,
      repEmail,
      customTeamFields,
      ...restTeam
    } = formTeam;

    const formattedTeam: Team = {
      ...restTeam,
      id: team.id,
      name: teamName,
      admin1: state ?? province ?? '',
      status: team.status,
      metadata: {
        version: '1.0',
        teamRepresentatives:
          repName && repPhoneNumber && repEmail
            ? [{ name: repName, phoneNumber: repPhoneNumber, email: repEmail }]
            : undefined,
        ageGroup: restTeam.ageGroup,
        formFields: customTeamFields.map((field) => ({
          values: Boolean(field.value) ? [field.value] : [],
          formFieldId: field.propertyDefinitionId,
        })),
      },
    };

    updateTeam({
      siteDomain: subdomain,
      programId: divisionId,
      team: formattedTeam,
    })
      .unwrap()
      .then((updatedTeam) => {
        setIsOpen(false);
        onUpdateTeam(updatedTeam.id, updatedTeam.ageGroup);
      });
  };

  const handleDeleteTeam = (deletedTeamId: string) => {
    setIsOpen(false);
    onDeleteTeam(deletedTeamId);
  };

  return (
    <Modal
      open={isOpen}
      onOpenChange={setIsOpen}
      primaryAction={
        formHasChanges ? (
          <S.UpdateTeamButton
            form={teamFormId}
            loading={isUpdateTeamLoading}
            type="submit"
          >
            Update team
          </S.UpdateTeamButton>
        ) : (
          <ModalClose>
            <ModalPrimaryActionButton>Done</ModalPrimaryActionButton>
          </ModalClose>
        )
      }
      secondaryAction={
        <DeleteTeamDialog
          hasDivisionsWithTeamSelected={hasDivisionsWithTeamSelected}
          setHasDeleteTeamDivisionError={setHasDeleteTeamDivisionError}
          onDeleteTeam={handleDeleteTeam}
          team={team}
          tournamentId={tournamentId}
          closeUpdateModal={() => setIsOpen(false)}
        />
      }
      tertiaryAction={
        formHasChanges ? (
          <ModalClose>
            <ModalTertiaryActionButton>Cancel</ModalTertiaryActionButton>
          </ModalClose>
        ) : undefined
      }
      title="Update team"
      trigger={
        trigger ? (
          trigger
        ) : (
          <S.UpdateTeamTriggerButton
            ariaLabel={`Update team ${name}`}
            disabled={status !== 'DRAFT'}
            variant="text"
            icon={<EditIcon />}
          />
        )
      }
    >
      {updateTeamError ? (
        <ErrorCard message={UPDATE_TEAM_ERROR_MESSAGE} />
      ) : null}
      <CreateTeamForm
        defaultValues={defaultValues}
        id={teamFormId}
        maxDivisionAgeGroup={maxDivisionAgeGroup}
        onChange={setUpdatedTeam}
        onSubmit={handleUpdateTeam}
        customFields={customFields}
        existingFields={team.formFields}
      />
    </Modal>
  );
}

type DeleteTeamDialogProps = Pick<
  UpdateTeamDialogProps,
  | 'hasDivisionsWithTeamSelected'
  | 'onDeleteTeam'
  | 'team'
  | 'tournamentId'
  | 'setHasDeleteTeamDivisionError'
> & {
  closeUpdateModal: () => void;
};

function DeleteTeamDialog({
  hasDivisionsWithTeamSelected,
  onDeleteTeam,
  team,
  tournamentId,
  setHasDeleteTeamDivisionError,
  closeUpdateModal,
}: DeleteTeamDialogProps) {
  const { subdomain } = getLAHostnameParts();
  const [isOpen, setIsOpen] = useState<boolean>();

  const [
    deleteTeam,
    { isLoading: isDeleteTeamLoading, error: deleteTeamError, reset },
  ] = useDeleteTeamMutation();

  /**
   * Resets the query state so that errors do not show when
   * reopening the modal.
   */
  useEffect(() => {
    if (!isOpen) {
      reset();
    }
  }, [isOpen, reset]);

  const handleDeleteTeam = () => {
    if (hasDivisionsWithTeamSelected) {
      setHasDeleteTeamDivisionError(true);
      setIsOpen(false);
      closeUpdateModal();
      return;
    }
    const { id } = team;
    deleteTeam({
      siteDomain: subdomain,
      programId: tournamentId,
      editURL: `/app/tournaments/${tournamentId}/registration`,
      teamId: id,
    })
      .unwrap()
      .then(() => {
        setIsOpen(false);
        onDeleteTeam(id);
      });
  };

  return (
    <Modal
      open={isOpen}
      onOpenChange={setIsOpen}
      title="Delete team?"
      primaryAction={
        <S.DeleteTeamButton
          loading={isDeleteTeamLoading}
          onClick={handleDeleteTeam}
        >
          Delete team
        </S.DeleteTeamButton>
      }
      tertiaryAction={
        <ModalClose>
          <ModalSecondaryActionButton>Cancel</ModalSecondaryActionButton>
        </ModalClose>
      }
      trigger={
        <ModalSecondaryActionButton>Delete team</ModalSecondaryActionButton>
      }
    >
      {deleteTeamError ? (
        <ErrorCard message={DELETE_TEAM_ERROR_MESSAGE} />
      ) : null}
      <Typography size="large" variant="ui">
        Are you sure you want to delete this team altogether?
      </Typography>
    </Modal>
  );
}
